import { useEffect, useState } from "react"
import { data } from "@remix-run/node"
import type { ActionFunctionArgs } from "@remix-run/node"
import { useFetcher, useFetchers } from "@remix-run/react"
import { MonitorIcon, MoonIcon, SunIcon } from "lucide-react"
import { z } from "zod"

import { useRequestInfo } from "@/lib/hooks/use-request-info"
import { useHints } from "@/lib/utils/client-hints"
import { setTheme } from "@/lib/utils/theme-cookie.server"
import type { Theme } from "@/lib/utils/theme-cookie.server"
import { Button } from "@/components/ui/button"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"

const ThemeFormSchema = z.object({
	theme: z.enum(["system", "light", "dark"]),
})

export function useOptimisticThemeMode() {
	const fetchers = useFetchers()
	const themeFetcher = fetchers.find(
		(f) => f.formAction === "/resources/theme-switch",
	)

	if (themeFetcher && themeFetcher.formData) {
		const theme = themeFetcher.formData.get("theme")
		if (theme && ThemeFormSchema.shape.theme.safeParse(theme).success) {
			return theme as "system" | "light" | "dark"
		}
	}
}

export function useTheme() {
	const hints = useHints()
	const requestInfo = useRequestInfo()
	const optimisticMode = useOptimisticThemeMode()
	if (optimisticMode) {
		return optimisticMode === "system" ? hints.theme : optimisticMode
	}
	return requestInfo.userPrefs.theme ?? hints.theme
}

export function ThemeSwitch({
	userPreference,
}: {
	userPreference?: Theme | null
}) {
	const [mounted, setMounted] = useState(false)
	const fetcher = useFetcher()
	const optimisticMode = useOptimisticThemeMode()

	const mode = optimisticMode ?? userPreference ?? "system"

	useEffect(() => {
		setMounted(true)
	}, [])

	if (!mounted) {
		return null
	}

	const setTheme = (theme: "light" | "dark" | "system") => {
		fetcher.submit(
			{ theme },
			{ method: "POST", action: "/resources/theme-switch" },
		)
	}

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost" size="icon">
					{mode === "light" && <SunIcon className="size-[1.2rem]" />}
					{mode === "dark" && <MoonIcon className="size-[1.2rem]" />}
					{mode === "system" && (
						<MonitorIcon className="size-[1.2rem]" />
					)}
					<span className="sr-only">Toggle theme</span>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="end">
				<DropdownMenuItem onClick={() => setTheme("light")}>
					Light
				</DropdownMenuItem>
				<DropdownMenuItem onClick={() => setTheme("dark")}>
					Dark
				</DropdownMenuItem>
				<DropdownMenuItem onClick={() => setTheme("system")}>
					System
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}

export async function action({ request }: ActionFunctionArgs) {
	const formData = await request.formData()
	const result = ThemeFormSchema.safeParse(Object.fromEntries(formData))

	if (!result.success) {
		return data({ error: "Invalid theme received" }, { status: 400 })
	}

	const { theme } = result.data

	const responseInit = {
		headers: { "set-cookie": await setTheme(theme) },
	}

	return data({ success: true }, { ...responseInit, status: 200 })
}
