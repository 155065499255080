export function TailwindIndicator() {
	if (process.env.NODE_ENV === "production") return null

	return (
		<div className="pointer-events-none fixed bottom-2 left-2 z-50 flex size-6 touch-none select-none items-center justify-center rounded-full bg-black/70 font-mono text-sm text-white hover:opacity-0">
			<div className="block sm:hidden">xs</div>
			<div className="hidden sm:block md:hidden">sm</div>
			<div className="hidden md:block lg:hidden">md</div>
			<div className="hidden lg:block xl:hidden">lg</div>
			<div className="hidden xl:block 2xl:hidden">xl</div>
			<div className="hidden 2xl:block 3xl:hidden">2xl</div>
			<div className="hidden 3xl:block 4xl:hidden">3xl</div>
			<div className="hidden 4xl:block">4xl</div>
		</div>
	)
}
