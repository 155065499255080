import { Toaster as Sonner } from "sonner"

type ToasterProps = React.ComponentProps<typeof Sonner>

const Toaster = ({ ...props }: ToasterProps) => {
	return <Sonner toastOptions={{}} {...props} />
}

export { toast } from "sonner"
export { Toaster }
