import { useEffect } from "react"
import { getHintUtils } from "@epic-web/client-hints"
import {
	clientHint as colorSchemeHint,
	subscribeToSchemeChange,
} from "@epic-web/client-hints/color-scheme"
import { useRevalidator } from "@remix-run/react"

import { useRequestInfo } from "../hooks/use-request-info"

const hintsUtils = getHintUtils({ theme: colorSchemeHint })

export const { getHints } = hintsUtils

export function ClientHintCheck() {
	const { revalidate } = useRevalidator()
	useEffect(() => subscribeToSchemeChange(() => revalidate()), [revalidate])

	return (
		<script
			dangerouslySetInnerHTML={{
				__html: hintsUtils.getClientHintCheckScript(),
			}}
		/>
	)
}

/**
 * @returns an object with the client hints and their values
 */
export function useHints() {
	const requestInfo = useRequestInfo()
	return requestInfo.hints
}
